.d-side-handle {
  display: none;
}

.d-open-side-handle,
.d-close-side-handle {
  position: absolute;
  top: calc(40% - 50px);
  width: 25px;
  height: 60px;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  font-size: 1.3rem;
  background: var(--primary-color);
  z-index: 20;
}

.d-open-side-handle svg,
.d-close-side-handle svg {
  margin-top: 18px;
}

.d-open-side-handle {
  left: 4rem;
}

.d-close-side-handle {
  left: 18rem;
}

.d-user-sidebar,
.d-user-sidebar-mobile {
  height: 100vh;
  background: var(--primary-color);
}

.d-user-sidebar {
  overflow-y: auto;
  width: 100%;
  flex: 0 0 18rem;
}

.d-sidebar-inner {
  padding: 0 10px;
  align-items: center;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.d-sidebar-logo {
  width: 100%;
  margin-bottom: auto;
}

.d-sidebar-menu {
  width: 100%;
}

.d-sidebar-menu a {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--black);
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 1.3rem;
  transition: var(--transition-all);
}

.d-bg-change,
.d-sidebar-menu a:hover {
  background-color: var(--green-100);
}

.d-sidebar-menu p {
  font-weight: 500;
}

@media screen and (max-width: 1023px) {
  .d-side-handle {
    display: block;
  }

  .d-user-sidebar-test {
    position: absolute;
    width: 18rem;
    z-index: 200;
  }

  .d-user-sidebar-mobile {
    flex: 0 0 4rem;
    overflow: hidden;
    overflow-y: auto;
    z-index: 1;
  }

  .d-user-sidebar-mobile .d-sidebar-logo {
    position: relative;
    left: 90px;
    width: 14rem;
  }

  .d-sidebar-menu-open {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .d-sidebar-menu a {
    font-size: 1rem;
  }

  .d-user-sidebar-test {
    width: 14rem;
  }

  .d-close-side-handle {
    left: 14rem;
  }
}
