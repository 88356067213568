.d-secondary-btn {
  all: unset;
  background-color: var(--secondary-color);
  width: 100%;
  text-align: center;
  border-radius: 10px;
  padding: 5px;
  transition: var(--transition-all);
}

.d-secondary-btn:hover {
  background-color: var(--hover-color);
  color: white;
}
