@media screen and (max-width: 767px) {
  .contact-header {
    margin-top: 4rem;
  }

  .modal {
    width: 90%;
    margin: 3rem 1rem;
  }
}
