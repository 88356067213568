.addbook {
  background-color: var(--tertiary-color);
}

.addbook:hover {
  color: white;
}

.super-theme {
  background-color: red;
}

.book-buttons {
  border: 1px solid var(--tertiary-color);
  background-color: white;
}

.css-162mkon {
  display: none;
  background-color: red;
  visibility: hidden;
}

@media screen and (max-width: 767px) {
  .modal {
    width: 90%;
    margin: 3rem 1rem;
  }

  .box {
    width: 70%;
    margin: 0 auto;
  }
}
