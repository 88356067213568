.d-ticket-wrapper {
  margin: 0 10px;
}

.d-ticket-wrapper h3 {
  text-align: center;
  margin: 20px 0;
}

.d-ticket-list {
  width: 100%;
  margin-top: 20px;
  overflow-y: auto;
  max-height: 700px;
}

.d-ticket-list .d-tickets {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.d-sub-btn {
  all: unset;
  cursor: pointer;
  background-color: var(--black);
  transition: all 0.5s ease-in-out;
  color: var(--white);
  border-radius: 10px;
  font-size: 11px;
  padding: 6px;
}

.d-sub-btn:hover {
  background-color: var(--primary-color);
  color: var(--black);
}

@media screen and (min-width: 768px) {
  .d-ticket-wrapper h3 {
    text-align: left;
  }

  .d-ticket-list {
    margin: 20px auto;
  }

  .d-ticket-list span {
    font-size: var(--ptext2);
  }
  .d-ticket-list .d-sub-btn {
    padding: 8px;
    font-size: 16px;
  }
}
