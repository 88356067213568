@media screen and (max-width: 767px) {
  .set-header {
    margin-top: 3rem;
  }

  .modal {
    width: 90%;
    margin: 3rem 1rem;
  }
}
