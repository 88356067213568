.announce {
  width: 80%;
  margin: 1rem auto;
  border-radius: 10px;
}

.addannounce {
  background-color: var(--tertiary-color);
  border: none;
}

.addannounce:hover {
  background-color: var(--post-tertiary-color);
  border: none;
  color: white;
}

.annouce-box {
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding: 2rem 0;
}

.annouce-wrap {
  width: 80%;
  margin: 1rem auto;
  padding: 2rem 0;
  border-radius: 10px;
}

.announce-buttons button {
  border: 1px solid var(--green-500);
  background-color: white;
  padding: 0.3rem 1.5rem;
  margin-left: 0.5rem;
}

@media screen and (max-width: 767px) {
  .announce-header {
    margin-top: 4rem;
  }

  .modal {
    width: 90%;
    margin: 3rem 1rem;
  }

  .box {
    width: 70%;
    margin: 0 auto;
  }
}
