.d-user-nav {
  position: fixed;
  background: var(--primary-color);
  z-index: 100;
  width: 100%;
  top: 0;
}

.d-user-nav-items {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.d-nav-logo {
  width: 18rem;
  text-align: center;
}

.d-nav-cta {
  background-color: var(--white);
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid var(--black);
  width: 22rem;
  max-height: 4rem;
}

.d-windows-img {
  width: 3rem;
}

.d-nav-cta p:nth-child(2) {
  color: var(--light-text-color);
}

.d-nav-balance {
  display: flex;
  align-items: center;
  width: 13rem;
  margin-left: auto;
  background-color: var(--white);
  padding: 10px;
  border: 1px solid var(--black);
  border-radius: 15px;
}

.d-nav-balance .d-price {
  width: 50%;
}

.d-nav-balance .d-pricep:nth-child(1) {
  color: var(--light-text-color);
}

.d-nav-balance .d-price p:nth-child(2) {
  font-weight: 700;
}

.d-nav-balance a {
  width: 50%;
  font-size: 1rem;
  background: var(--secondary-color);
  text-align: center;
  border-radius: 10px;
  padding: 5px;
  transition: var(--transition-all);
}

.d-nav-balance a .d-text {
  margin-left: 2px;
}

.d-nav-balance a:hover {
  background-color: var(--primary-color);
}

.d-user-dropdown {
  color: var(--green-100);
  margin-left: 20px;
  padding: 10px;
  position: relative;
  bottom: 0.8rem;
  display: flex;
  align-items: center;
  width: 6rem;
  align-self: flex-end;
  cursor: pointer;
}

.d-drop-icon {
  font-size: 50px;
}

.d-drop-icon-2 {
  font-size: 20px;
}

.d-user-dropdown .d-dropdown-inner {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--white);
  border-radius: 10px;
  padding: 10px;
  display: none;
  gap: 10px;
  width: 200px;
  z-index: 100;
  transition: var(--transition-all);
  box-shadow: var(--box-shadow);
}

.d-user-dropdown:hover .d-dropdown-inner {
  display: block;
}

.d-user-dropdown .d-dropdown-inner > div {
  color: var(--light-text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.d-user-dropdown .d-dropdown-inner a {
  font-size: var(--link-text);
  display: flex;
  color: var(--black);
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 10px 0;
  transition: var(--transition-bg);
  align-items: center;
}

.d-user-dropdown .d-dropdown-inner a:hover {
  background-color: var(--green-100);
  border-radius: 10px;
}

@media screen and (max-width: 1023px) {
  .d-user-nav {
    left: 3.8rem;
    z-index: 10;
    width: calc(100% - 3.7rem);
  }

  .d-nav-logo {
    display: none;
  }

  .d-nav-cta {
    padding: 8px;
    width: auto;
  }

  .d-nav-balance {
    padding: 5px;
  }

  .d-nav-balance .d-price .d-bal,
  .d-nav-balance a .d-text {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .d-nav-cta {
    display: none;
  }

  .d-nav-balance {
    width: 11rem;
    justify-content: center;
  }

  .d-nav-balance a {
    width: 30%;
    align-self: flex-end;
  }

  .d-nav-balance .d-price {
    width: 50%;
  }
}
@media screen and (max-width: 425px) {
  .d-nav-balance .d-price {
    width: 65%;
  }

  .d-user-dropdown {
    margin-left: 0px;
  }

  .d-drop-icon {
    font-size: 40px;
  }
}
