.d-inner-layout img {
  width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.user-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbarn for IE, Edge and Firefox */
.user-scroll {
  -ms-overflow-style: none; /*IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.d-inner-layout p {
  margin: 0;
  padding: 0;
}

.d-inner-layout a {
  text-decoration: none;
  color: var(--black);
}

.d-inner-layout {
  display: flex;
  align-items: center;
}

.d-children {
  width: 100%;
  height: 100vh;
  padding: 70px 0 0;
  background-color: var(--green-100);
  overflow-y: scroll;
}

.d-gif-div {
  text-align: center;
}

.d-gif {
  max-width: 20%;
}

@media screen and (min-width: 1000px) {
  .d-children {
    padding-top: 95px;
  }
}
