@media screen and (min-width: 576px){
  .draft {
    max-width: 839px;
    /* max-width: 100%; */
  }

  .draft .css-bx0blc{
    max-width: 40%;
  }
}

@media screen and (max-width: 767px) {
  .blog-header {
    margin-top: 2rem;
  }

  .modal {
    width: 90%;
    margin: 3rem 1rem;
  }
  .box {
    width: 70%;
    margin: 0 auto;
  }
}
