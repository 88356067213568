@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700;800&display=swap");

:root {
  --primary-color: #aec7aa;
  --secondary-color: #fea800;
  --white: #ffffff;
  --black: #000000;
  --hover-color: #fec700;
  --light-text-color: #777a76;
  --tertiary-color: #ffaa33;
  --post-tertiary-color: #e88600;
  --green-100: #e3efe1;
  --green-200: #91ec80;
  --green-400: #52682d;
  --green-500: #4c6021;
  --green-600: #264b20;
  --fail: #fd0000;
  --pend: #b5506172;
  --box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  --box-shadow-hover: 0 0 10px rgba(0, 0, 0, 0.783);
  --link-text: 1.2rem;
  --ptext2: 1.5rem;
  --transition-all: all 0.5s ease-in-out;
  --transition-bg: background 0.5s ease-in-out;
  --brown: #e68d00;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Jost", sans-serif;
}

a {
  text-decoration: none;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}

.editor-class {
  background-color: lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}

.public-DraftEditor-content img{
  width: 100% !important;
}

.toolbar-class {
  border: 1px solid #ccc;
}

.toolbar-class img {
  width: auto !important;
}

form input {
  width: 100%;
  padding: 10px 5px;
}

.fx {
  margin: 20px 0 20px 20px;
}

.fx-group {
  margin: 0 0 0 1.5rem;
}

.fx-group input {
  padding: 5px 9px;
}

.fx-group button {
  all: unset;
  text-align: center;
  padding: 10px 5px;
  background: var(--black);
  border-radius: 1rem;
  color: white;
  width: 70px;
  margin: 0 0 0 .5rem;
  transition: var(--transition-all);
  cursor: pointer;
}

.fx-group button:hover {
  color: var(--secondary-color);
}

.fx-group p{
  margin: 10px 0 0 !important;
}

.d-gif-div {
  text-align: center;
}

.d-gif {
  max-width: 20%;
}