.bar-wrap {
  width: 90%;
  margin: 0 auto;
}

.bar {
  color: black;
  background-color: white;
  border-radius: 10px;
}

